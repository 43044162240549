<div
  class="new-message-window"
  [ngClass]="{
    'current-chat': isModal,
    'message-window': !isModal,
    'chat-admin': isAdmin && currentPath.includes('/ubs-admin'),
    'chat-user': !isAdmin && currentPath.includes('/ubs-user')
  }"
>
  <div *ngIf="!isModal" class="new-msg-title">
    <span class="msg-title">New Message</span>
    <button class="close-new-msg" (click)="close()" title="Close">&times;</button>
  </div>
  <div *ngIf="!isModal" class="receiver">
    <div *ngIf="chatsService.currentChat" class="account">
      <img [src]="chatIcons.userLogo" alt="user-logo" />
      <span class="chat-name">{{ chatsService.currentChat.name }}</span>
    </div>
  </div>

  <div class="chat" #chat>
    <div *ngIf="isHaveMessages" class="chat-messages">
      <div *ngFor="let message of currentChatMessages | async" class="message-container">
        <div *ngIf="message.isFirstOfDay" class="chat-days-separate-line">
          <span>{{ message.createDate | date: 'dd.MM.yyyy' }}</span>
        </div>
        <app-chat-message [message]="message"></app-chat-message>
      </div>
    </div>
    <div *ngIf="!isHaveMessages">Chat hasn't messages yet</div>
    <app-spinner *ngIf="chatsService.messagesIsLoading"></app-spinner>
  </div>

  <form (ngSubmit)="sendMessage()" [ngClass]="{ hidden: isAdmin && (chatsService.isAdminParticipant$ | async) === false }">
    <div>
      <div *ngIf="isEditMode" class="message-hint">
        <img [src]="chatIcons.edit" alt="edit" />
        <span class="message-content">{{ messageToEdit.content }}</span>
        <button *ngIf="isEditMode" class="cancel-editing" (click)="closeEditMode()">
          <img [src]="chatIcons.cancel" alt="cancel edit" />
        </button>
      </div>

      <div *ngIf="uploadedFile" class="attached-file-container">
        <div class="attached-file">
          <div class="file-box">
            <img [src]="chatIcons.file" class="file-icon" alt="file" title="added file" />
            <p>{{ uploadedFile?.name }}</p>
          </div>
          <button (click)="uploadedFile = null">
            <img [src]="chatIcons.cancel" alt="cancel file" />
          </button>
        </div>
      </div>
    </div>

    <div class="message-sender">
      <div class="sender-additional-buttons">
        <div *ngIf="!isEditMode" class="file-upload">
          <label for="file-input">
            <img [src]="chatIcons.paperClip" alt="clip" title="Add file" />
          </label>
          <input id="file-input" type="file" (change)="fileChanges($event)" onclick="this.value=null;" />
        </div>

        <button *ngIf="!chatsService.isSupportChat" type="button">
          <img [src]="chatIcons.smile" alt="smile" title="Add emoji" (click)="toggleEmojiPicker()" />
        </button>
      </div>
      <div role="textbox" contenteditable type="text" placeholder="Type something..." #customInput (input)="changeValue($event)"></div>
      <button
        class="send-button"
        type="submit"
        [disabled]="(!messageControl.value && !uploadedFile) || !chatsService.currentChat || isMessageSending"
      >
        <img [src]="chatIcons.send" alt="send" title="Send mail" />
      </button>
    </div>
  </form>
  <div *ngIf="isAdmin && (chatsService.isAdminParticipant$ | async) === false" class="join-chat">
    <button (click)="chatsService.addAdminToChat(userService.userId)">Join chat</button>
  </div>

  <emoji-mart
    *ngIf="showEmojiPicker"
    class="emoji-mart"
    [emojiSize]="20"
    [totalFrequentLines]="2"
    [darkMode]="false"
    [showPreview]="false"
    (emojiSelect)="addEmoji($event)"
    title="Pick your emoji…"
  ></emoji-mart>
</div>
