<div class="search_item-wrp">
  <div class="search_item-tag" *ngFor="let tag of searchModel.tags">
    {{ tag }}
  </div>
  <div class="search_item-title">
    <a [routerLink]="['/' + type, searchModel.id]">
      <p>
        {{ searchModel.title | slice: 0 : 130 }}
      </p>
    </a>
  </div>
</div>
