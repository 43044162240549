<div class="main-container">
  <div class="warning-text">
    <div class="warning-title" *ngIf="popupTitle">{{ popupTitle | translate }}</div>
    <div class="warning-subtitle" *ngIf="popupSubtitle">{{ popupSubtitle | translate }}</div>
  </div>
  <div class="buttons-container">
    <button
      *ngIf="isCancelButtonShow"
      [ngClass]="{
        'secondary-global-button': setBtnStyleRed,
        'secondary-global-button-green': setBtnStyleGreen,
        'ubs-employee-secondary-global-button': setBtnStyleLightGreen
      }"
      (click)="userReply(false)"
    >
      {{ popupCancel | translate }}
    </button>
    <button
      [ngClass]="{
        'primary-global-button': setBtnStyleRed,
        'primary-global-button-green': setBtnStyleGreen,
        'ubs-employee-global-button': setBtnStyleLightGreen
      }"
      (click)="userReply(true)"
    >
      {{ popupConfirm | translate }}
    </button>
  </div>
</div>
