<ng-template #bottomSheet>
  <div
    [@openCloseResize]="{ value: animationState, params: { resizingHeight: resizingHeight, height: height } }"
    #bottomSheetContainer
    style="touch-action: none"
  >
    <div (touchstart)="onHandleTouchStart($event)" class="close-handle" #resizeHandle>
      <svg width="40" height="3" viewBox="0 0 40 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="3" rx="1.5" fill="#CACFD3" />
      </svg>
    </div>
    <ng-content></ng-content>
  </div>
</ng-template>
