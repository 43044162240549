'use strict';

var EventEmitter = require('events').EventEmitter,
  inherits = require('inherits');
function XHRFake( /* method, url, payload, opts */
) {
  var self = this;
  EventEmitter.call(this);
  this.to = setTimeout(function () {
    self.emit('finish', 200, '{}');
  }, XHRFake.timeout);
}
inherits(XHRFake, EventEmitter);
XHRFake.prototype.close = function () {
  clearTimeout(this.to);
};
XHRFake.timeout = 2000;
module.exports = XHRFake;