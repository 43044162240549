<div class="restore-password-container">
  <h1>{{ 'user.auth.forgot-password.title' | translate }}</h1>
  <h2>{{ 'user.auth.forgot-password.fill-form' | translate }}</h2>
  <form class="restore-password-form" [formGroup]="restorePasswordForm" (ngSubmit)="sentEmail()">
    <label for="email">{{ 'user.auth.sign-in.email' | translate }}</label>
    <div class="input-wrapper">
      <input
        id="email"
        type="email"
        placeholder="example@email.com"
        formControlName="email"
        name="email"
        required
        [ngClass]="classCheck()"
        (input)="configDefaultErrorMessage()"
      />
      <div *ngIf="emailField.invalid && emailField.touched" class="validation-email-error">
        <app-error [control]="email"></app-error>
      </div>
      <div *ngIf="emailErrorMessageBackEnd === 'already-sent'" class="validation-email-error">
        {{ 'user.auth.forgot-password.already-sent' | translate }}: {{ emailFieldValue }}
      </div>
      <div *ngIf="emailErrorMessageBackEnd === 'email-not-exist'" class="validation-email-error">
        {{ 'user.auth.forgot-password.email-not-exist' | translate }}: {{ emailFieldValue }}
      </div>
    </div>
    <button [ngClass]="isUbs ? 'ubs-send-btn' : 'green-send-btn'" type="submit" [disabled]="!restorePasswordForm.valid">
      <span *ngIf="loadingAnim" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span>
      {{ 'user.auth.forgot-password.send-btn' | translate }}
    </button>
    <span class="or-use-google">{{ 'user.auth.sign-up.or' | translate }}</span>
    <app-google-btn [text]="'user.auth.sign-in.sign-in-with-google'" (click)="signInWithGoogle()"></app-google-btn>
  </form>
  <div class="mentioned-password">
    <p>
      {{ 'user.auth.forgot-password.remember-your-password' | translate }}
      <a tabindex="0" [ngClass]="isUbs ? 'ubs-link' : 'green-link'" (keyup.enter)="signInWithGoogle()" (click)="onBackToSignIn('sign-in')">
        {{ 'user.auth.forgot-password.back-to-the-sign-in' | translate }}
      </a>
    </p>
  </div>
</div>
