var C = Object.defineProperty;
var P = (o, t, i) => t in o ? C(o, t, {
  enumerable: !0,
  configurable: !0,
  writable: !0,
  value: i
}) : o[t] = i;
var h = (o, t, i) => (P(o, typeof t != "symbol" ? t + "" : t, i), i);
const m = o => {
  var i;
  return (i = o == null ? void 0 : o.clientId) != null ? i : o == null ? void 0 : o.client_id;
};
class k {
  constructor({
    clientId: t,
    onScriptLoadError: i,
    onScriptLoadSuccess: n
  }) {
    h(this, "clientId");
    h(this, "scriptLoadedSuccessfully", !1);
    h(this, "containerHeightMap", {
      large: "40",
      medium: "32",
      small: "20"
    });
    this.clientId = t, !(typeof window > "u") && this.useLoadGsiScript({
      onScriptLoadError: i,
      onScriptLoadSuccess: n
    });
  }
  useLoadGsiScript({
    onScriptLoadSuccess: t,
    onScriptLoadError: i
  } = {}) {
    const n = document.createElement("script");
    n.src = "https://accounts.google.com/gsi/client", n.async = !0, n.defer = !0, n.onload = () => {
      this.scriptLoadedSuccessfully = !0, t && t();
    }, n.onerror = () => {
      this.scriptLoadedSuccessfully = !1, i && i();
    }, document.body.appendChild(n);
  }
  useGoogleLogin(t) {
    return i => this.useGoogleLoginPostponed(t)(i);
  }
  useGoogleLoginPostponed({
    flow: t = "implicit",
    scope: i = "",
    onSuccess: n,
    onError: u,
    onNonOAuthError: d,
    overrideScope: g,
    ...l
  }) {
    var w;
    if (!this.scriptLoadedSuccessfully) return () => {};
    const r = t === "implicit" ? "initTokenClient" : "initCodeClient",
      e = (w = window.google) == null ? void 0 : w.accounts.oauth2[r]({
        client_id: this.clientId,
        scope: g ? i : `openid profile email ${i}`,
        callback: c => {
          if (u && c.error) return u(c);
          n && n(c);
        },
        error_callback: c => {
          d && d(c);
        },
        ...l
      });
    return t === "implicit" ? c => e.requestAccessToken(c) : () => e.requestCode();
  }
  useGoogleOneTapLogin(t) {
    return () => {
      this.useGoogleOneTapLoginPostponed(t);
    };
  }
  useGoogleOneTapLoginPostponed({
    onError: t,
    onSuccess: i,
    hosted_domain: n,
    use_fedcm_for_prompt: u,
    cancel_on_tap_outside: d,
    promptMomentNotification: g
  }) {
    var l, r;
    typeof window > "u" || !this.scriptLoadedSuccessfully || ((l = window.google) == null || l.accounts.id.initialize({
      hosted_domain: n,
      use_fedcm_for_prompt: u,
      cancel_on_tap_outside: d,
      client_id: this.clientId,
      callback: e => {
        if (!(e != null && e.credential) && t) return t();
        const {
          credential: s,
          select_by: f
        } = e;
        i({
          select_by: f,
          credential: s,
          clientId: m(e)
        });
      }
    }), (r = window.google) == null || r.accounts.id.prompt(g));
  }
  useRenderButton(t) {
    return () => {
      this.useRenderButtonPostponed(t);
    };
  }
  useRenderButtonPostponed({
    onSuccess: t,
    onError: i,
    useOneTap: n,
    promptMomentNotification: u,
    type: d = "standard",
    theme: g = "outline",
    size: l = "large",
    text: r,
    shape: e,
    element: s,
    logo_alignment: f,
    width: w,
    locale: c,
    click_listener: I,
    use_fedcm_for_prompt: L,
    ...S
  }) {
    var p, y, G;
    !this.scriptLoadedSuccessfully || ((p = window.google) == null || p.accounts.id.initialize({
      client_id: this.clientId,
      use_fedcm_for_prompt: L,
      callback: a => {
        if (!(a != null && a.credential) && i) return i();
        const {
          credential: b,
          select_by: A
        } = a;
        t({
          select_by: A,
          credential: b,
          clientId: m(a)
        });
      },
      ...S
    }), s.style.height = this.containerHeightMap[l], (y = window.google) == null || y.accounts.id.renderButton(s, {
      type: d,
      size: l,
      text: r,
      theme: g,
      shape: e,
      width: w,
      locale: c,
      logo_alignment: f,
      click_listener: I
    }), n && ((G = window.google) == null || G.accounts.id.prompt(u)));
  }
}
function T() {
  var o;
  typeof window > "u" || (o = window.google) == null || o.accounts.id.disableAutoSelect();
}
function B(o, t, ...i) {
  return window.google ? window.google.accounts.oauth2.hasGrantedAnyScope(o, t, ...i) : !1;
}
function F(o, t, ...i) {
  return window.google ? window.google.accounts.oauth2.hasGrantedAllScopes(o, t, ...i) : !1;
}
export { k as GoogleOAuthProvider, T as googleLogout, F as hasGrantedAllScopesGoogle, B as hasGrantedAnyScopeGoogle };