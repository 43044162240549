<div class="main">
  <mat-drawer-container class="sidebar" autosize role="navigation" aria-label="Sidebar menu">
    <mat-drawer
      #drawer
      [ngClass]="isAdmin ? 'admin-sidebar-slider' : 'sidebar-slider'"
      mode="side"
      opened="true"
      disableClose="true"
      autoFocus="false"
      aria-labelledby="sidebar-list"
    >
      <ul id="sidebar-list" class="sidebar-list">
        <li class="toggle-arrow">
          <button
            mat-icon-button
            [attr.aria-label]="isExpanded ? 'Collapse sidebar menu' : 'Expand sidebar menu'"
            (click)="toggleMenu()"
            role="button"
          >
            <mat-icon>{{ isExpanded ? 'arrow_back' : 'arrow_forward' }}</mat-icon>
          </button>
        </li>
        <li
          *ngFor="let listItem of listElements"
          class="sidebar-list-item"
          role="listitem"
          [attr.aria-label]="listItem.name | translate"
          (keydown.enter)="navigateToPage($event, listItem.routerLink)"
          (keydown.space)="navigateToPage($event, listItem.routerLink)"
          [matTooltip]="!isExpanded ? (listItem.name | translate) : null"
          matTooltipPosition="left"
        >
          <a
            class="sidebar-list-item-link"
            [ngClass]="{ 'sidebar-list-item-link__collapsed': !isExpanded }"
            [routerLink]="listItem.routerLink"
            [routerLinkActive]="['active']"
          >
            <img [src]="getIcon(listItem)" class="sidebar-list-item-icon" alt="{{ listItem.name | translate }} icon" />
            <span *ngIf="isExpanded" class="sidebar-list-item-desc">{{ listItem.name | translate }}</span>
            <span
              *ngIf="listItem.name === 'ubs-user.messages' && serviceUserMessages.countOfNoReadeMessages && isExpanded"
              class="count-unread-messages"
              aria-live="polite"
            >
              ({{ serviceUserMessages.countOfNoReadeMessages }})
            </span>
          </a>
        </li>
      </ul>
    </mat-drawer>

    <mat-drawer-content [ngClass]="isAdmin ? 'admin-main-content' : 'main-content'" role="main">
      <div [ngClass]="{ 'margin-for-table': !isAdmin, 'sidebar-container': true }" #sidebarContainer>
        <ng-content></ng-content>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>

  <div class="mobile-sidebar">
    <ul class="sidebar-list">
      <li
        *ngFor="let listItem of listElementsMobile"
        [routerLink]="listItem.routerLink"
        [routerLinkActive]="['active']"
        class="sidebar-list-item-mobile"
      >
        <img [src]="getIcon(listItem)" class="sidebar-list-item-icon" alt="sidebar list item icon" />
        <span class="sidebar-list-item-text">{{ listItem.name | translate }}</span>
      </li>
    </ul>
  </div>
</div>
