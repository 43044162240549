<div class="list-gallery">
  <img class="list-image-content" role="img" [src]="checkNewsImage()" alt="user added image" />
  <div class="list-gallery-content">
    <div class="filter-tag" role="list">
      <div role="none" aria-hidden="false" class="ul-eco-buttons" *ngFor="let tag of tags">
        {{ tag }}
      </div>
    </div>
    <div class="added-data">
      <div class="title-list word-wrap">
        <h3>
          {{ ecoNewsModel.title }}
        </h3>
      </div>
      <div class="list-text word-wrap ql-snow">
        <div #ecoNewsText></div>
      </div>
    </div>
    <div class="user-data-added-news">
      <div class="user-data">
        <p class="user-data-text-date">
          <img class="icon" [src]="profileIcons.calendarIcon" alt="date of creation" />
          <span>{{ newDate }}</span>
        </p>
        <p class="user-data-text-date user">
          <img class="icon" role="img" [src]="profileIcons.userIcon" alt="created by" />
          <span>{{ ecoNewsModel.author.name }}</span>
        </p>
      </div>
      <div>
        <p class="user-data-like">
          <img [src]="commentImg" role="img" alt="comments" class="icon" />
          <span class="numerosity">{{ ecoNewsModel.countComments }}</span>
        </p>
        <p class="user-data-like">
          <img [src]="likeImg" role="img" alt="likes" class="icon" />
          <span class="numerosity">{{ ecoNewsModel.likes }}</span>
        </p>
      </div>
    </div>
  </div>
</div>
