<div class="wrapper">
  <span class="filter">{{ header | translate }}</span>
  <div class="ul-eco-buttons" aria-label="filter by items">
    <button *ngFor="let filter of filters" class="tag-button" (click)="toggleFilter(filter.name)">
      <a class="custom-chip global-tag" [ngClass]="{ 'global-tag-clicked': filter.isActive }">
        <span class="text" [appLangValue]="{ ua: filter.nameUa, en: filter.name }"></span>
        <div [ngClass]="{ 'global-tag-close-icon': filter.isActive }"></div>
      </a>
    </button>
  </div>
</div>
