<div class="chat-modal-wrapper">
  <button class="close-modal-button" type="button" (click)="close()" title="Close">
    <img [src]="isMobile ? chatIcons.cancel : chatIcons.close" alt="close" />
  </button>
  <div class="controlPanel">
    <div *ngIf="!chatsService.isSupportChat" class="switchView">
      <img [src]="chatIcons.profile" alt="profile" />
      <img [src]="chatIcons.chats" alt="profile" />
    </div>
    <img *ngIf="!chatsService.isSupportChat" [src]="chatIcons.setting" alt="profile" />
  </div>

  <mat-tab-group #tabGroup *ngIf="isMobile; else desktop">
    <mat-tab label="Chat list">
      <div class="chats">
        <app-chats-list (click)="tabGroup.selectedIndex = 1"></app-chats-list></div
    ></mat-tab>
    <mat-tab label="Chat"> <app-current-chat></app-current-chat></mat-tab>
  </mat-tab-group>

  <ng-template #desktop>
    <div class="chats">
      <app-chats-list></app-chats-list>
    </div>
    <app-current-chat></app-current-chat
  ></ng-template>
</div>
