<div class="main-container">
  <button *ngIf="!isUBS || isUbsOrderSubmit" class="close" (click)="userReply(false)">
    <img src="{{ closeButton }}" alt="close" />
  </button>
  <div class="warning-text">
    <div class="warning-title" *ngIf="popupTitle">
      {{ popupTitle | translate }} <span *ngIf="isHabit"> "{{ habitName }}" {{ popupSubtitle | translate }}</span>
    </div>
    <div class="warning-subtitle" *ngIf="popupSubtitle && !isHabit">
      {{ popupSubtitle | translate }}
    </div>
  </div>
  <div class="buttons-container">
    <button [ngClass]="isUBS ? 'ubs-secondary-global-button' : 'secondary-global-button'" class="m-btn" (click)="userReply(false)">
      {{ popupCancel | translate }}
    </button>
    <button
      [ngClass]="isUBS ? 'ubs-primary-global-button' : 'primary-global-button'"
      class="m-btn"
      [disabled]="isLoading"
      (click)="userReply(true)"
    >
      <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span>
      {{ popupConfirm | translate }}
    </button>
  </div>
</div>
