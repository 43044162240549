<div [ngStyle]="getMainContainerStyle()" class="main-container">
  <div class="main-container__title">{{ 'ubs-employee.download-photo.title' | translate }}</div>
  <div class="main-container__cropper-block">
    <image-cropper
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="false"
      [roundCropper]="true"
      [resizeToHeight]="600"
      [resizeToWidth]="400"
      [imageFile]="data.file.file"
      (imageCropped)="imageCropped($event)"
      class="main-container__selected-photo"
    >
    </image-cropper>
  </div>
  <div class="main-container__bottom">
    <button class="ubs-secondary-global-button" (click)="onCancel()">{{ 'ubs-employee.download-photo.cancel' | translate }}</button>
    <button class="ubs-primary-global-button" (click)="onSaveChanges()">{{ 'ubs-employee.download-photo.save' | translate }}</button>
  </div>
</div>
