{
  "parts": {
    "specificTime": "о %s:%s",
    "and": "та",
    "minute": {
      "every": "щохвилини",
      "value": "кожну %s хвилину",
      "list": "кожну %s хвилину",
      "range": "щохвилини з %s до %s"
    },
    "hour": {
      "every": "",
      "value": "після %s години",
      "list": "після %s години",
      "range": "щогодини з %s до %s"
    },
    "dayOfMonth": {
      "every": "",
      "value": "у %s день місяця",
      "list": "у %s день місяця",
      "range": "щодня з %s до %s дня місяця"
    },
    "month": {
      "every": "",
      "value": "у %s",
      "list": "у %s",
      "range": "щомісяця з %s до %s"
    },
    "dayOfWeek": {
      "every": "",
      "value": "у %s",
      "list": "у %s",
      "range": "щодня з %s до %s"
    }
  },
  "months": ["Січ", "Лют", "Бер", "Лип", "Трав", "Черв", "Лип", "Серп", "Вер", "Жовт", "Лист", "Груд"],
  "daysOfWeek": ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Нд"]
}
