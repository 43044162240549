<div class="modal-inner">
  <h3 class="modal-title">
    {{
      isRegistered
        ? ('homepage.events.modal-rating-title-can-evaluate' | translate)
        : ('homepage.events.modal-rating-title-can`t-evaluate' | translate)
    }}
  </h3>
  <div class="modal-rating" [hidden]="!isRegistered">
    <rating
      (onHover)="hoveringOver($event)"
      (onLeave)="hoveringOver(0)"
      (click)="hoveringOver($event, true)"
      [(ngModel)]="rate"
      [customTemplate]="star"
      [max]="max"
      [readonly]="isReadonly"
    >
    </rating>
    <ng-template #star let-index="index" let-value="value">
      <span (click)="starsHandler(value, index)" class="star {{ index < value ? 'fill' : 'empty' }}"></span>
    </ng-template>
    <p class="modal-text">{{ (hover ? text : textByRate) | translate }}</p>
  </div>

  <div class="modal-bnt-group">
    <button class="secondary-global-button modal-btn modal-btn-cancel" (click)="bsModalRef.hide()">
      {{ 'event.btn-cancel-rate' | translate }}
    </button>
    <button [ngClass]="{ disabled: !isEventRaited }" (click)="modalBtn()" class="primary-global-button modal-btn">
      {{ isRegistered ? ('event.btn-save' | translate) : ('event.btn-register' | translate) }}
    </button>
  </div>
</div>
