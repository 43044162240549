<div class="current-chat" *ngIf="chatsService.currentChat; else noCurrentChatPlaceholder">
  <div class="current-chat-title">
    <img [src]="chatIcons.userLogo" alt="user logo" />
    <span class="current-chat-name">{{ chatsService.currentChat.name }}</span>
  </div>
  <div class="current-chat-content">
    <app-new-message-window [isModal]="true"></app-new-message-window>
  </div>
</div>

<ng-template #noCurrentChatPlaceholder>
  <div class="current-chat-title">
    <span class="current-chat-name">Choose a Chat</span>
  </div>
  <div class="current-chat-content no-current-chat">
    <img class="green-people-ph" [src]="chatIcons.greenPeople" alt="people" />
  </div>
</ng-template>
