export const CHAT_ICONS = {
  chat: 'assets/img/chat/chat.svg',
  newMessage: 'assets/img/chat/new-message.svg',
  paperClip: 'assets/img/chat/paper-clip.svg',
  settings: 'assets/img/chat/settings.svg',
  threeDots: 'assets/img/chat/three-dots.svg',
  userLogo: 'assets/img/chat/user.svg',
  supportChatLogo: 'assets/img/chat/support-chat.svg',
  close: 'assets/img/chat/close.svg',
  greenPeople: 'assets/img/illustration-people.png',
  smile: 'assets/img/chat/smile.svg',
  friends: 'assets/img/chat/friends.svg',
  file: 'assets/img/chat/file.svg',
  image: 'assets/img/chat/image.svg',
  send: 'assets/img/chat/send.svg',
  voice: 'assets/img/chat/mic.svg',
  search: 'assets/img/chat/search.svg',
  chats: 'assets/img/chat/chats.svg',
  setting: 'assets/img/chat/setting.svg',
  profile: 'assets/img/chat/profile.svg',
  edit: 'assets/img/profile/icons/edit.svg',
  delete: 'assets/img/profile/icons/delete.svg',
  cancel: 'assets/img/auth/cross.svg',
  download: 'assets/img/chat/download.svg',
  like: 'assets/img/comments/like.png',
  liked: 'assets/img/comments/liked.png'
};
