<div *ngIf="filter" class="filters-dropdown">
  <mat-label #selectLabel class="filter-label" (click)="selectFilter.open()">{{ filter.title | translate }}</mat-label>
  <mat-select #selectFilter panelClass="green-city-select" [multiple]="true">
    <mat-option [value]="0" (click)="toggleAllSelection()">{{ filter.selectAllOption | translate }}</mat-option>
    <mat-divider></mat-divider>
    <mat-option *ngFor="let option of filter.options" (click)="updateSelectedFilters(option)">
      <span [appLangValue]="{ ua: option.nameUa, en: option.name }"></span>
    </mat-option>
  </mat-select>
</div>
