<div class="chat-popup" [ngClass]="chatClass" [class.opened]="isOpen">
  <div class="popup-top-panel">
    <div class="panel-account" (click)="handlePanelClick()">
      <span class="panel-account-title">{{ (isSupportChat ? 'chat.support-chat' : 'chat.messages') | translate }}</span>
    </div>

    <div class="panel-buttons">
      <button class="panel-button">
        <img [src]="chatIcons.chat" (click)="openChatModal()" alt="open chat" title="Open chat" />
      </button>
      <button *ngIf="!isSupportChat" class="panel-button" type="button" (click)="hideChat()" title="Close">
        <img [src]="chatIcons.close" alt="hide" />
      </button>
    </div>
  </div>
  <app-chats-list *ngIf="isOpen" [isPopup]="true" (createNewMessageWindow)="openNewMessageWindow(false)"></app-chats-list>
</div>

<div class="new-msg-container" *appReferenceDirective></div>
