<div class="main-container cdk-overlay-dark-backdrop">
  <div class="wrapper">
    <div class="left-side">
      <img class="main-picture" [src]="authImages.mainImage" alt="sign-in image" />
    </div>
    <div class="right-side">
      <a class="close-modal-window">
        <img class="cross-btn" [src]="closeBtn.closeButton" (click)="closeModal()" alt="close button" />
      </a>
      <div class="confirm-password-container">
        <h1>{{ 'user.auth.restore-password.title' | translate }}</h1>
        <h2>{{ 'user.auth.restore-password.fill-form' | translate }}</h2>
        <div class="restore-password">
          <form class="restore-password-form" (ngSubmit)="sendPasswords()" [formGroup]="confirmRestorePasswordForm">
            <label for="password" class="content-label under-error">
              {{ 'user.auth.restore-password.password' | translate }}
            </label>
            <div
              class="main-data-input-password"
              [ngClass]="
                (passwordField.invalid && passwordField.touched) ||
                (passwordField.value !== confirmPasswordField.value && confirmPasswordField.value !== '')
                  ? 'main-data-input-password wrong-input'
                  : 'main-data-input-password'
              "
            >
              <input
                type="password"
                placeholder="{{ 'user.auth.restore-password.password' | translate }}"
                class="password-input"
                #inputPassword
                formControlName="password"
                required
                minlength="8"
                name="form-control password"
                (input)="setPasswordBackendErr()"
              />
              <span class="show-password">
                <img
                  [src]="authImages.hiddenEye"
                  alt="eye"
                  class="show-password-img"
                  #inputPasswordImg
                  (click)="setPasswordVisibility(inputPassword, inputPasswordImg)"
                />
              </span>
            </div>
            <div *ngIf="passwordField.invalid && passwordField.touched" class="error-message-show error-message">
              <app-error [control]="password"></app-error>
            </div>
            <label for="password" class="content-label under-error">
              {{ 'user.auth.restore-password.password-confirm' | translate }}
            </label>
            <div
              class="main-data-input-password"
              [ngClass]="
                (confirmPasswordField.invalid && confirmPasswordField.touched) ||
                (passwordField.value !== confirmPasswordField.value && confirmPasswordField.value !== '')
                  ? 'main-data-input-password wrong-input'
                  : 'main-data-input-password'
              "
              #repeatInput
            >
              <input
                type="password"
                placeholder="{{ 'user.auth.restore-password.password' | translate }}"
                class="password-input"
                #repeatPassword
                formControlName="confirmPassword"
                name="form-control password-confirm"
                required
                (input)="setPasswordBackendErr()"
              />
              <span class="show-password">
                <img
                  [src]="authImages.hiddenEye"
                  alt="eye"
                  class="show-password-img"
                  #repeatPasswordImg
                  (click)="setPasswordVisibility(repeatPassword, repeatPasswordImg)"
                />
              </span>
            </div>
            <div
              *ngIf="confirmPasswordField.invalid && (confirmPasswordField.dirty || confirmPasswordField.touched)"
              class="error-message-show error-message"
            >
              <app-error [control]="confirmPassword"></app-error>
            </div>
            <button
              [ngClass]="isUbs ? 'ubsStyle' : 'greenStyle'"
              [disabled]="confirmRestorePasswordForm.invalid || passwordField.value !== confirmPasswordField.value"
              type="submit"
            >
              <span *ngIf="loadingAnim" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span>
              {{ 'user.auth.restore-password.send-btn' | translate }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
