<div class="habit-wrp">
  <div class="habit-container">
    <div class="habit-header">
      <div class="calendar">
        <img class="calendar-icon" [src]="calendarGreen" alt="calendar" />
        <span class="days" prefix="{{ habit.defaultDuration }}" [appLangValue]="{ ua: ' ДНІВ', en: ' DAYS' }"></span>
      </div>
      <div class="stars">
        <img *ngFor="let star of stars" [src]="star" alt="star" />
      </div>
    </div>
    <div class="habit-picture">
      <img [src]="habit.image" (error)="habit.image = man" alt="habit" />
    </div>
    <div class="habit-info">
      <div class="tags">
        <span *ngFor="let tag of habit.tags; let last = last">
          {{ last ? tag : tag + ',&nbsp;' }}
        </span>
      </div>
      <div class="title">
        <h2
          [appLangValue]="{
            ua: habit.habitTranslation.nameUa ? habit.habitTranslation.nameUa : habit.habitTranslation.name,
            en: habit.habitTranslation.name
          }"
        >
          habit name
        </h2>
      </div>
      <div class="acquired">
        <p>{{ 'user.habit.header.aсquired' | translate }} {{ habit.amountAcquiredUsers }} {{ 'user.habit.header.users' | translate }}</p>
      </div>
    </div>
    <div class="habit-action-wrp">
      <div>
        <button class="secondary-global-button m-btn" (click)="goHabitMore()">
          {{ 'user.habit.all-habits.btn-more' | translate }}
        </button>
      </div>
      <div>
        <button [disabled]="habit.isAssigned" class="primary-global-button m-btn" (click)="assignStandardHabit()">
          {{ habit.isAssigned ? ('user.habit.all-habits.btn-added' | translate) : ('user.habit.all-habits.btn-add' | translate) }}
        </button>
      </div>
    </div>
  </div>
</div>
