<div class="eco-events">
  <div class="eco-events-wrapper" *ngIf="latestNews.length > 0">
    <div class="main-event">
      <img [src]="latestNews[0]?.imagePath || eventImg" alt="event-image" class="main-event-image" aria-hidden="true" />
      <app-eco-events-item [ecoEvent]="latestNews[0]" [mainEvent]="true"></app-eco-events-item>
    </div>
    <div class="other-events">
      <app-eco-events-item [ecoEvent]="latestNews[1]"></app-eco-events-item>
      <div class="splitter"></div>
      <app-eco-events-item [ecoEvent]="latestNews[2]"></app-eco-events-item>
    </div>
  </div>
  <a class="centered" [routerLink]="['/news']" aria-label="link to eco-news page">
    {{ 'homepage.eco-news.read-all' | translate }}
    <img [src]="arrow" alt="arrow" aria-hidden="true" />
  </a>
</div>
