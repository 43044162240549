<div class="profile-avatar-wrapper">
  <img
    *ngIf="sanitizedImgPath"
    class="profile-avatar"
    referrerpolicy="no-referrer"
    alt="avatar"
    [ngClass]="['profile-avatar', additionalImgClass]"
    [src]="sanitizedImgPath"
  />
  <div *ngIf="!imgPath" [ngClass]="['profile-avatar', additionalImgClass, 'default-avatar']">
    <p>{{ getDefaultProfileImg() }}</p>
  </div>
  <div class="status-indicator" *ngIf="isOnline"></div>
</div>
