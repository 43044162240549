export function getXHRResponse(xhr) {
  switch (xhr.responseType) {
    case 'json':
      {
        if ('response' in xhr) {
          return xhr.response;
        } else {
          const ieXHR = xhr;
          return JSON.parse(ieXHR.responseText);
        }
      }
    case 'document':
      return xhr.responseXML;
    case 'text':
    default:
      {
        if ('response' in xhr) {
          return xhr.response;
        } else {
          const ieXHR = xhr;
          return ieXHR.responseText;
        }
      }
  }
}
