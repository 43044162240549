<div class="content">
  <h3 class="title">Edit image</h3>

  <ng-container *ngIf="!isLoading && imageFile; else spinner">
    <image-cropper [imageFile]="imageFile" [aspectRatio]="data.aspectRatio" (imageCropped)="onImageCropped($event)"></image-cropper>
  </ng-container>

  <div class="controls">
    <button class="cancel secondary-global-button" (click)="onCancel()">Cancel</button>
    <button class="save primary-global-button" (click)="onSave()">Save</button>
  </div>
</div>

<ng-template #spinner>
  <mat-spinner></mat-spinner>
</ng-template>
