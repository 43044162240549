<section *ngIf="isSearchClicked">
  <div class="search_bar-wrapper">
    <div class="container search_bar-container">
      <img class="search_search-icon" [src]="searchIcons.search" alt="search" />
      <input
        #inputFocus
        class="search_search-field"
        type="text"
        [formControl]="searchInput"
        placeholder="{{ 'search.search-popup.placeholder' | translate }}"
      />
      {{ inputFocus.focus() }}
      <img
        class="search_close-icon"
        [src]="searchIcons.union"
        alt="close search"
        (click)="closeSearch()"
        (keydown.enter)="closeSearch()"
        tabindex="0"
      />
    </div>
  </div>
  <div class="search_body-wrapper">
    <div class="search-content-wrapper container">
      <div class="search_remaining-items" *ngIf="!isLoading && searchInput.value">
        {{ itemsFound }} {{ 'search.search-popup.items-found' | translate }}
      </div>
      <div *ngIf="newsElements && newsElements.length > 0">
        <a [routerLink]="['/news']" (click)="closeSearch()">
          <h3 class="search-title">{{ 'search.search-popup.news' | translate }}</h3>
        </a>
        <div class="list-search-items">
          <app-search-item
            *ngFor="let element of newsElements | slice: 0 : 3"
            [searchModel]="element"
            [type]="'news'"
            (closeSearch)="closeSearch()"
            class="search-item"
          >
          </app-search-item>
        </div>
      </div>
      <div *ngIf="eventsElements && eventsElements.length > 0">
        <a [routerLink]="['/events']" (click)="closeSearch()">
          <h3 class="search-title">{{ 'search.search-popup.events' | translate }}</h3>
        </a>
        <div class="list-search-items">
          <app-search-item
            *ngFor="let element of eventsElements | slice: 0 : 3"
            [searchModel]="element"
            [type]="'events'"
            (closeSearch)="closeSearch()"
            class="search-item"
          >
          </app-search-item>
        </div>
      </div>
      <div class="search_see-all" *ngIf="itemsFound">
        <a
          [routerLink]="['/search']"
          [queryParams]="{ query: searchInput.value, category: newsElements && newsElements.length > 0 ? 'econews' : 'events' }"
          class="all-results-link"
        >
          {{ 'search.search-popup.see-all-results' | translate }}
        </a>
      </div>

      <app-search-not-found *ngIf="itemsFound === 0" [inputValue]="searchInput.value" (closeUnsuccessfulSearchResults)="closeSearch()">
      </app-search-not-found>
    </div>
    <app-spinner class="search_spinner" *ngIf="isLoading"></app-spinner>
  </div>
</section>
