<div class="main">
  <div class="wrapper">
    <div class="left-side">
      <img
        class="main-picture"
        [src]="authImages.mainImage"
        alt="sign-in image that illustrates a man in a yellow T-shirt and blue jeans looking up at a picture with green leaves with raised hands, which symbolizes devotion to the idea of the site"
        aria-hidden="true"
      />
    </div>
    <div class="right-side">
      <a (click)="closeWindow()" (keydown.enter)="closeWindow()" class="close-modal-window" aria-label="close form button" tabindex="0">
        <img class="cross-btn" [src]="authImages.cross" alt="close button" />
      </a>
      <div [ngSwitch]="authPage">
        <app-sign-in *ngSwitchCase="'sign-in'" (pageName)="changeAuthPage($event)" [isUbs]="isUbs"></app-sign-in>
        <app-sign-up *ngSwitchCase="'sign-up'" (pageName)="changeAuthPage($event)" [isUbs]="isUbs"></app-sign-up>
        <app-restore-password *ngSwitchCase="'restore-password'" (pageName)="changeAuthPage($event)" [isUbs]="isUbs"></app-restore-password>
      </div>
    </div>
  </div>
</div>
