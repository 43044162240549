<div id="subscribe">
  <div id="subscribe-wrapper">
    <div id="qr-code-wrapper">
      <img [src]="qrCode" alt="Scan this QR-code To access the mobile version of the app" />
    </div>
    <div id="form-wrapper">
      <h2>{{ 'homepage.subscription.caption' | translate }}</h2>
      <p>{{ 'homepage.subscription.content' | translate }}</p>
      <div class="form-input">
        <input
          #subscriptionEmail
          type="email"
          [(ngModel)]="email"
          (input)="validateEmail()"
          placeholder="{{ 'homepage.subscription.placeholder' | translate }}"
        />
        <p id="validation-error" [ngClass]="{ hidden: !emailTouched || emailValid, visible: emailTouched && !emailValid }">
          {{ 'homepage.subscription.validation-error' | translate }}
        </p>
        <p
          id="subscription-error"
          [ngClass]="{ hidden: subscriptionError.length < 1, visible: !emailTouched || subscriptionError.length > 0 }"
        >
          {{ 'homepage.subscription.subscription-error' | translate }}
        </p>
        <button class="primary-global-button btn" (click)="subscribe()">
          {{ 'homepage.subscription.button-subscribe' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
