<div class="main-container" [ngSwitch]="true">
  <div class="wrapper" *ngSwitchCase="!selectedPhoto && !isNotification && !isDragAndDropMenu">
    <app-user-profile-image [imgPath]="avatarImg" [additionalImgClass]="'big'" [firstName]="data.firstName"> </app-user-profile-image>
    <p *ngIf="isWarning" class="warning">{{ 'user.edit-profile.picture-tooltip' | translate }}</p>
    <p *ngIf="!isWarning">{{ 'user.edit-profile.change-photo' | translate }}</p>
    <div class="buttons">
      <button type="button" class="tertiary-global-button m-btn" (click)="closeEditPhoto()" tabindex="0">
        {{ 'user.edit-profile.btn.cancel' | translate }}
      </button>
      <button type="button" class="secondary-global-button m-btn" [disabled]="!avatarImg" (click)="isNotification = true" tabindex="0">
        {{ 'user.edit-profile.btn.delete' | translate }}
      </button>
      <button class="primary-global-button m-btn" (click)="isDragAndDropMenu = true">
        {{ 'user.edit-profile.btn.new-photo' | translate }}
      </button>
    </div>
  </div>
  <div *ngSwitchDefault>
    <div class="wrapper">
      <div class="cropper-block">
        <app-drag-and-drop
          class="selected-photo"
          [height]="148"
          [aspectRatio]="1"
          [roundCropper]="true"
          [isDisabledButton]="true"
          (newFile)="imageCropped($event)"
        >
        </app-drag-and-drop>
      </div>
      <p *ngIf="isWarning" class="warning">{{ 'user.edit-profile.picture-tooltip' | translate }}</p>
      <p *ngIf="!isWarning">{{ 'user.edit-profile.would-you-like' | translate }}</p>
      <div class="buttons">
        <button type="button" class="tertiary-global-button m-btn" (click)="closeEditPhoto()">
          {{ 'user.edit-profile.btn.cancel' | translate }}
        </button>
        <button class="secondary-global-button m-btn" (click)="selectedPhoto = false; files.pop()">
          {{ 'user.edit-profile.btn.change-photo' | translate }}
        </button>
        <button type="button" class="primary-global-button m-btn" [disabled]="isWarning" (click)="savePhoto()" tabindex="0">
          <span *ngIf="loadingAnim" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{ 'user.edit-profile.btn.save-photo' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isNotification" class="wrapper">
    <p>{{ 'user.edit-profile.notification' | translate }}</p>
    <div class="buttons">
      <button type="button" class="secondary-global-button m-btn" (click)="isNotification = false">
        {{ 'user.edit-profile.btn.continue' | translate }}
      </button>
      <button type="button" class="primary-global-button m-btn" (click)="deletePhoto()">
        <span *ngIf="loadingAnim" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ 'user.edit-profile.btn.yes-delete' | translate }}
      </button>
    </div>
  </div>
  <button class="cancel" (click)="closeEditPhoto()" tabindex="0">
    <img src="{{ cancelButton }}" alt="cancel" />
  </button>
</div>
