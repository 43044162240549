<div class="calendar-habits-open">
  <p class="habits-title">{{ habitsCalendarSelectedDate | titlecase }}</p>
  <ul class="habits-list" *ngFor="let habit of popupHabits" [ngClass]="{ disabled: isHabitListEditable }">
    <li class="habits-item">
      <img
        class="habit-icon"
        [src]="habit.enrolled ? this.calendarIcons.checkCircle : this.calendarIcons.plusCircle"
        alt="habit-icon"
        (click)="toggleEnrollHabit(habit.habitAssignId)"
      />
      <span ngbPopover="{{ habit.habitName }}" triggers="mouseenter:mouseleave" [disablePopover]="showTooltip(habit)">{{
        habit.habitName
      }}</span>
    </li>
  </ul>
</div>
