<form [formGroup]="addAddressForm" class="row adress">
  <div class="w-100">
    <h2 *ngIf="!data.edit" class="personal-info-pop-up-title">{{ 'personal-info.pop-up-title' | translate }}</h2>
    <h2 *ngIf="data.edit" class="personal-info-pop-up-title">{{ 'personal-info.pop-up-title-edit' | translate }}</h2>
  </div>
  <app-address-input
    formControlName="address"
    [address]="data.address"
    [edit]="data.edit"
    [addFromProfile]="data.addFromProfile"
  ></app-address-input>
</form>
<div class="buttons" mat-dialog-actions>
  <button *ngIf="data.edit" class="ubs-danger-global-button m-btn delete-btn" (click)="deleteAddress()">
    {{ 'personal-info.pop-up-delete' | translate }}
  </button>
  <button class="ubs-secondary-global-button m-btn" (click)="onNoClick()">
    {{ 'personal-info.pop-up-cancel' | translate }}
  </button>
  <button class="ubs-primary-global-button m-btn" (click)="addAdress()" [disabled]="addAddressForm.invalid">
    {{ (data.edit ? 'personal-info.pop-up-save-changes' : 'personal-info.pop-up-add-address') | translate }}
  </button>
</div>
