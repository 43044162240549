<div class="container">
  <div class="picture">
    <img [src]="imagePath" alt="No data available" />
  </div>
  <div class="description">
    <div class="description__title">
      <h2>
        {{ title | translate }}
      </h2>
    </div>
    <div class="description__advise">
      <p>
        {{ text | translate }}
      </p>
    </div>
  </div>
</div>
