<div class="container">
  <div>
    <h1>
      {{ 'user.auth.sign-in.title' | translate }}
    </h1>
    <h2>{{ 'user.auth.sign-in.fill-form' | translate }}</h2>
    <form *ngIf="signInForm" class="sign-in-form" [formGroup]="signInForm" (ngSubmit)="signIn()">
      <label for="email" aria-required="true">{{ 'user.auth.sign-in.email' | translate }}</label>
      <input
        id="email"
        type="email"
        placeholder="example@email.com"
        formControlName="email"
        [ngClass]="{
          'alert-email-validation': email.invalid && email.touched,
          'successful-email-validation': email.valid || email.untouched
        }"
        aria-required="true"
        aria-describedby="email-err-msg"
        tabindex="0"
      />
      <div id="email-err-msg" class="validation-email-error">
        <app-error [control]="email"></app-error>
      </div>
      <div *ngIf="error$ | async as error" class="email-field-margin"></div>
      <label for="password" aria-required="true">{{ 'user.auth.sign-in.password' | translate }}</label>
      <div
        class="password-input-btn-show-hide"
        [ngClass]="{
          'alert-password-validation': password.invalid && password.touched,
          'successful-password-validation': password.valid || password.untouched
        }"
      >
        <input
          #input
          id="password"
          type="password"
          formControlName="password"
          placeholder="{{ 'user.auth.sign-in.password' | translate }}"
          aria-required="true"
          aria-describedby="pass-err-msg"
        />
        <span class="show-hide-btn" role="button" tabindex="0" aria-label="show password">
          <img
            [src]="hideShowPasswordImage.hidePassword"
            #src
            alt="show-hide-password"
            class="image-show-hide-password"
            (click)="togglePassword(input, src)"
            (keydown.enter)="togglePassword(input, src)"
          />
        </span>
      </div>
      <div id="pass-err-msg" class="validation-password-error">
        <app-error [control]="password"></app-error>
      </div>
      <div *ngIf="error$ | async as error" class="alert-general-error">
        {{ error | translate }}
      </div>
      <div *ngIf="!isUbs" class="forgot-wrapper">
        <a
          class="forgot-password"
          tabindex="0"
          (click)="onOpenModalWindow('restore-password')"
          (keyup.enter)="onOpenModalWindow('restore-password')"
        >
          {{ 'user.auth.sign-in.forgot-password' | translate }}</a
        >
      </div>
      <button
        [ngClass]="isUbs ? 'ubsStyle' : 'greenStyle'"
        [disabled]="(email.invalid || !password.touched) && password.invalid"
        type="submit"
      >
        <span *ngIf="isLoading$ | async" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ 'user.auth.sign-in.sign-in' | translate }}
      </button>

      <span class="or-use-google">{{ 'user.auth.sign-in.or-signin' | translate }}</span>
    </form>
    <app-google-btn
      [text]="'user.auth.sign-in.sign-in-with-google'"
      (click)="signInWithGoogle()"
      aria-label="Sign in with Google"
    ></app-google-btn>

    <div *ngIf="isUbs" class="forgot-wrapper-ubs">
      <a
        class="forgot-password-ubs"
        tabindex="0"
        (click)="onOpenModalWindow('restore-password')"
        (keyup.enter)="onOpenModalWindow('restore-password')"
      >
        {{ 'user.auth.sign-in.forgot-password' | translate }}</a
      >
    </div>
  </div>
  <div class="missing-account">
    <p>
      {{ 'user.auth.sign-in.havenot-account' | translate }}
      <a
        [ngClass]="isUbs ? 'ubs-link' : 'green-link'"
        tabindex="0"
        aria-label="sign up modal window"
        (click)="onOpenModalWindow('sign-up')"
        (keyup.enter)="onOpenModalWindow('sign-up')"
      >
        {{ 'user.auth.sign-in.sign-up' | translate }}
      </a>
    </p>
  </div>
</div>
