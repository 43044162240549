<p class="warning" [ngClass]="{ 'warning-color': isWarning }">{{ 'drag-and-drop.picture-tooltip' | translate }}</p>
<div *ngIf="!file && !isWarning" class="dropzone" appDragAndDrop (files)="filesDropped($event)">
  <div class="text-wrapper">
    <div class="centered">
      {{ 'drag-and-drop.picture-description' | translate }}
      <label for="upload">
        <span>{{ 'drag-and-drop.browse' | translate }}</span>
      </label>
      <input type="file" id="upload" (change)="onFileSelected($event)" />
    </div>
  </div>
</div>
<div *ngIf="!isWarning; else elseBlock">
  <div *ngIf="!isCropper" class="image-preview">
    <img [src]="file?.url" appDragAndDrop (files)="filesDropped($event)" alt="img-cropper" />
  </div>
  <div *ngIf="isCropper" class="cropper-block">
    <image-cropper
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="false"
      [resizeToHeight]="height"
      [roundCropper]="roundCropper"
      [aspectRatio]="aspectRatio"
      [imageChangedEvent]="imageChangedEvent"
      [imageFile]="file?.file"
      (imageCropped)="imageCropped($event)"
      class="cropper"
    >
    </image-cropper>
  </div>
  <div class="cropper-buttons" *ngIf="!isDisabledButton">
    <button class="secondary-global-button s-btn" type="button" (click)="cancelChanges()">
      {{ 'drag-and-drop.cancel-changes-button' | translate }}
    </button>
    <button class="primary-global-button s-btn" type="button" (click)="stopCropping()" [disabled]="!this.isCropper">
      {{ 'drag-and-drop.submit-changes-button' | translate }}
    </button>
  </div>
</div>
<ng-template #elseBlock>
  <div class="dropzone warning-background" appDragAndDrop (files)="filesDropped($event)">
    <div class="text-wrapper">
      <div class="centered">
        <p>{{ 'drag-and-drop.not-uploaded-warning' | translate }}</p>
        <p>
          {{ 'drag-and-drop.picture-description' | translate }}
          <label for="upload">
            <span>{{ 'drag-and-drop.browse' | translate }}</span>
          </label>
          <input type="file" id="upload" (change)="onFileSelected($event)" />
        </p>
      </div>
    </div>
  </div>
</ng-template>
